/* eslint-disable no-unused-expressions */
import { helpers, email, required } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const connectionValidationMixin = {
	validations: {
		cmsConnection: {
			staffContactList: {
				$each: {
					phone: { phoneCheck },
					eMail: { email },
				},
			},
			chatBotCode: {},
			chatBotProviderId: {},
			pixelCode: {},
			whatsAppNotificationTypes: {
				$each: {
					name: required,
					type: required,
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		staffPhoneErrors(index) {
			const errors = [];
			const { $dirty, phoneCheck } = this.$v.cmsConnection.staffContactList.$each[index].phone;
			if (!$dirty) return errors;
			!phoneCheck && errors.push('Lütfen geçerli bir telefon numarası giriniz.');
			return errors;
		},
		staffEMailErrors(index) {
			const errors = [];
			const { $dirty, email } = this.$v.cmsConnection.staffContactList.$each[index].eMail;
			if (!$dirty) return errors;
			!email && errors.push('Lütfen geçerli bir email adres giriniz.');
			return errors;
		},
		chatBotCodeErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.cmsConnection.chatBotCode;
			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			return errors;
		},
		chatBotProviderErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.cmsConnection.chatBotProviderId;
			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			return errors;
		},
		pixelCodeErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.cmsConnection.pixelCode;
			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			return errors;
		},
		whatsAppNotificationNameErrors(index) {
			const errors = [];
			const { $dirty, phoneCheck } = this.$v.cmsConnection.whatsAppNotificationTypes.$each[index].name;
			if (!$dirty) return errors;
			!phoneCheck && errors.push('Bu bilgi gereklidir.');
			return errors;
		},
		whatsAppNotificationTypeErrors(index) {
			const errors = [];
			const { $dirty, email } = this.$v.cmsConnection.whatsAppNotificationTypes.$each[index].type;
			if (!$dirty) return errors;
			!email && errors.push('Bu bilgi gereklidir.');
			return errors;
		},
	},
};

export default connectionValidationMixin;
