<template>
	<v-card flat v-if="initialized">
		<v-card-title> Dış Bağlantı Ayarları </v-card-title>
		<v-card-text>
			<v-row dense>
				<v-col cols="12" md="6">
					<v-card flat>
						<v-card-title class="px-0"> Google Ayarları </v-card-title>
						<v-card-text class="pa-0">
							<v-row>
								<v-col>
									<v-text-field
										label="GTag ID (Analytics)"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.analyticsId"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field
										label="GTag Manager ID"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.gTagManagerId"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" md="6">
					<v-checkbox
						class="pt-0 mt-0"
						v-model="cmsConnection.isSmsEnabled"
						label="Sms Ayarları Aktif"
						hide-details="auto"
					></v-checkbox>
					<v-card flat v-if="cmsConnection.isSmsEnabled">
						<v-card-title> SMS Ayarları </v-card-title>
						<v-card-text>
							<v-row dense>
								<v-col>
									<v-select
										hide-details="auto"
										color="teal"
										label="Sms Sağlayıcı"
										outlined
										dense
										:items="smsProviderList"
										item-text="sprName"
										item-value="sprId"
										v-model="cmsConnection.smsProviderId"
									></v-select>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="SMS Başlığı"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.smsHeader"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="SMS Kullanıcı"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.smsUser"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="SMS Secret Key"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.smsSecret"
									>
									</v-text-field>
								</v-col>
								<v-col>
									<v-text-field
										label="Mersis No"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.mersisNo"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card flat>
						<v-card-title class="px-0"> Insider Ayarları </v-card-title>
						<v-card-text class="pa-0">
							<v-row>
								<v-col>
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsConnection.hasInsider"
										label="Insider Aktif"
										hide-details="auto"
										@change="hasInsiderSiteChanged"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row v-if="hasInsider">
								<v-col>
									<v-text-field
										label="Insider Sub Domain"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.insiderSubDomain"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row v-if="hasInsider">
								<v-col>
									<v-text-field
										label="Insider Id"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.insiderId"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card flat>
						<v-card-title class="px-0"> ChatBot Ayarları </v-card-title>
						<v-card-text class="pa-0">
							<v-row>
								<v-col>
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsConnection.isChatBot"
										label="ChatBot Aktif"
										hide-details="auto"
										@change="isChatBotChanged"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row v-if="isChatBot">
								<v-col>
									<v-select
										hide-details="auto"
										color="teal"
										label="ChatBot Sağlayıcı"
										outlined
										dense
										:items="chatProviderList"
										item-text="cprName"
										item-value="cprId"
										v-model="cmsConnection.chatBotProviderId"
										:error-messages="chatBotProviderErrors()"
									></v-select>
								</v-col>
							</v-row>
							<v-row v-if="isChatBot">
								<v-col>
									<v-text-field
										label="ChatBot Kodu"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.chatBotCode"
										:error-messages="chatBotCodeErrors()"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card flat>
						<v-card-title class="px-0"> Pixel Ayarları </v-card-title>
						<v-card-text class="pa-0">
							<v-row>
								<v-col>
									<v-checkbox
										class="pt-0 mt-0"
										v-model="cmsConnection.isPixel"
										label="Pixel Aktif"
										hide-details="auto"
										@change="isPixelChanged"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row v-if="isPixel">
								<v-col>
									<v-text-field
										label="Pixel Kodu"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.pixelCode"
										:error-messages="pixelCodeErrors()"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card flat>
						<v-card-title class="px-0">
							Personel Talep Bildirim Ayarları<v-spacer></v-spacer>
							<v-btn depressed class="text-capitalize" @click="newStaffContact">
								<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
								yeni
							</v-btn>
						</v-card-title>
						<v-card-text>
							<v-list dense>
								<v-list-item dense class="px-0 v-i" v-if="cmsConnection.staffContactList.length === 0">
									<v-list-item-content class="justify-center text-center">
										Henüz bir cep telefonu eklenmemiş.
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									dense
									class="px-0"
									v-for="(staffContact, index) in cmsConnection.staffContactList"
									:key="`staffContact-${index}`"
								>
									<v-list-item-content class="d-flex flex-row">
										<v-row>
											<v-col cols="6" md="6">
												<v-text-field-simplemask
													v-model="cmsConnection.staffContactList[index].phone"
													v-bind:label="'Cep Telefonu Numarası'"
													@input="$v.cmsConnection.staffContactList.$touch()"
													v-bind:properties="{
														inputmode: 'numeric',
														prefix: '+90',
														placeholder: '(555)-555-55-55',
														dense: true,
														outlined: true,
														class: 'rounded-lg',
														errorMessages: staffPhoneErrors(index),
														hideDetails: 'auto',
														flat: true,
													}"
													v-bind:options="{
														inputMask: '(###) ###-##-##',
														outputMask: '###########',
														empty: null,
														applyAfter: false,
														alphanumeric: false,
													}"
												/>
											</v-col>
											<v-col cols="6" md="6">
												<v-text-field
													label="E-Mail"
													outlined
													hide-details="auto"
													flat
													dense
													color="teal"
													v-model="cmsConnection.staffContactList[index].eMail"
													:error-messages="staffEMailErrors(index)"
												></v-text-field>
											</v-col>
										</v-row>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn
											outlined
											color="red darken-4"
											depressed
											class="text-capitalize"
											@click="removeStaffContact(index)"
										>
											<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
											sil
										</v-btn>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6">
					<v-checkbox
						class="pt-0 mt-0"
						v-model="cmsConnection.isWhatsappEnabled"
						label="Whatsapp Ayarları Aktif"
						hide-details="auto"
					></v-checkbox>
					<v-card flat v-if="cmsConnection.isWhatsappEnabled">
						<v-card-title> Whatsapp Ayarları </v-card-title>
						<v-card-text>
							<v-row dense>
								<v-col>
									<v-select
										hide-details="auto"
										color="teal"
										label="Whatsapp Sağlayıcı"
										outlined
										dense
										:items="whatsappProviderList"
										item-text="wprName"
										item-value="wprId"
										v-model="cmsConnection.whatsappProviderId"
									></v-select>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Secret Key"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.whatsappSecretKey"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Namespace"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.whatsappNamespace"
									>
									</v-text-field>
								</v-col>
								<v-col>
									<v-text-field
										label="Cliend Id"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="cmsConnection.whatsappClientId"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-card flat>
										<v-card-title class="px-0">
											Bildirim Seçenekleri<v-spacer></v-spacer>
											<v-btn depressed class="text-capitalize" @click="newWhatsAppNotificationType">
												<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
												yeni
											</v-btn>
										</v-card-title>
										<v-card-text>
											<v-list dense>
												<v-list-item dense class="px-0 v-i" v-if="cmsConnection.whatsAppNotificationTypes.length === 0">
													<v-list-item-content class="justify-center text-center">
														Henüz Whatsapp bildirim ayarları tanımlanmamış.
													</v-list-item-content>
												</v-list-item>
												<v-list-item
													dense
													class="px-0"
													v-for="(whatsAppNotificationType, index) in cmsConnection.whatsAppNotificationTypes"
													:key="`whatsAppNotificationType-${index}`"
												>
													<v-list-item-content class="d-flex flex-row">
														<v-row>
															<v-col cols="6" md="6">
																<v-text-field
																	label="Template"
																	outlined
																	hide-details="auto"
																	flat
																	dense
																	color="teal"
																	v-model="cmsConnection.whatsAppNotificationTypes[index].name"
																	:error-messages="whatsAppNotificationNameErrors(index)"
																></v-text-field>
															</v-col>
															<v-col cols="6" md="6">
																<v-select
																	outlined
																	label="Type"
																	item-text="name"
																	item-value="id"
																	:items="whatsAppNotificationTypes"
																	:return-object="false"
																	hide-details="auto"
																	dense
																	color="teal"
																	v-model="cmsConnection.whatsAppNotificationTypes[index].type"
																	:error-messages="whatsAppNotificationTypeErrors(index)"
																>
																</v-select>
															</v-col>
														</v-row>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn
															outlined
															color="red darken-4"
															depressed
															class="text-capitalize"
															@click="removeWhatsAppNotificationType(index)"
														>
															<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
															sil
														</v-btn>
													</v-list-item-action>
												</v-list-item>
											</v-list>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsConnection.$invalid"
					@click="save"
					:disabled="$v.cmsConnection.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { FETCH_SITE_SETTINGS, UPDATE_CONNECTION_SETTING } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import connectionValidationMixin from '@/mixins/validations/backoffice/connectionValidationMixin';

const defaultSmsSettings = {
	smsHeader: null,
	smsSecret: null,
	smsUser: null,
	smsProviderId: 1,
};

const defaultWhatsappSettings = {
	whatsappProviderId: 1,
	whatsappSecretKey: null,
	whatsappClientId: null,
	whatsappNamespace: null,
};

export default {
	name: 'Connection',
	mixins: [validationMixin, connectionValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsConnection: {
				analyticsId: null,
				smsHeader: null,
				smsUser: null,
				smsSecret: null,
				mersisNo: null,
				staffContactList: [],
				hasInsider: false,
				insiderSubDomain: null,
				insiderId: null,
				smsProviderId: null,
				gTagManagerId: null,
				isChatBot: false,
				chatBotProviderId: null,
				chatBotCode: null,
				isPixel: false,
				pixelCode: null,
				isSmsEnabled: false,
				isWhatsappEnabled: false,
				whatsappProviderId: null,
				whatsappSecretKey: null,
				whatsappNamespace: null,
				whatsappClientId: null,
				whatsAppNotificationTypes: [],
			},
			whatsAppNotificationTypes: [
				{
					id: 1,
					name: 'Yeni Talep',
				},
				{
					id: 2,
					name: 'Randevu Oluşturma / Güncelleme',
				},
			],
		};
	},

	computed: {
		...mapGetters(['settings']),
		initialized() {
			return this.cmsConnection !== null;
		},
		hasInsider() {
			return this.cmsConnection?.hasInsider;
		},
		smsProviderList() {
			return this.settings.smsProviderList;
		},
		isChatBot() {
			return this.cmsConnection?.isChatBot;
		},
		chatProviderList() {
			return this.settings.chatProviderList;
		},
		isPixel() {
			return this.cmsConnection?.isPixel;
		},
		whatsappProviderList() {
			return this.settings.whatsappProviderList;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateConnectionSetting: UPDATE_CONNECTION_SETTING,
		}),
		newStaffContact() {
			this.cmsConnection.staffContactList.push({
				phone: '',
				eMail: '',
			});
		},
		newWhatsAppNotificationType() {
			this.cmsConnection.whatsAppNotificationTypes.push({
				name: '',
				type: 1,
			});
		},
		removeStaffContact(index) {
			this.cmsConnection.staffContactList.splice(index, 1);
		},
		removeWhatsAppNotificationType(index) {
			this.cmsConnection.whatsAppNotificationTypes.splice(index, 1);
		},
		hasInsiderSiteChanged() {
			if (!this.cmsConnection.hasInsider) {
				this.cmsConnection.insiderSubDomain = '';
				this.cmsConnection.insiderId = 0;
			}
		},
		isChatBotChanged() {
			if (!this.cmsConnection.isChatBot) {
				if (this.cmsConnection.chatBotCode === null) {
					this.cmsConnection.chatBotCode = '';
					this.cmsConnection.chatBotProviderId = 0;
				}
			}
		},
		isPixelChanged() {
			if (!this.cmsConnection.isPixel) {
				if (this.cmsConnection.pixelCode === null) {
					this.cmsConnection.pixelCode = '';
				}
			}
		},
		save() {
			this.savePreloader = true;

			if (!this.cmsConnection.isWhatsappEnabled) {
				this.cmsConnection = {
					...this.cmsConnection,
					...defaultWhatsappSettings,
				};
			}

			if (!this.cmsConnection.isSmsEnabled) {
				this.cmsConnection = {
					...this.cmsConnection,
					...defaultSmsSettings,
				};
			}

			this.updateConnectionSetting(this.cmsConnection)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
					if (!this.cmsConnection.isChatBot) {
						this.cmsConnection.chatBotCode = '';
						this.cmsConnection.chatBotProviderId = 0;
					}
					if (!this.cmsConnection.isPixel) {
						this.cmsConnection.pixelCode = '';
					}
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsConnection = {
					analyticsId: company.cmpAnalyticsId,
					smsHeader: company.cmpSmsHeader,
					smsUser: company.cmpSmsUser,
					smsSecret: company.cmpSmsSecret,
					mersisNo: company.cmpMersisNo,
					staffContactList: company.staffContactList.map((x) => ({
						phone: x?.csmPhone?.substring(2, x?.csmPhone?.length),
						eMail: x.csmEMail,
					})),
					hasInsider: company.cmpHasInsider,
					insiderSubDomain: company.cmpInsiderSubDomain,
					insiderId: company.cmpInsiderId,
					smsProviderId: company.cmpSprId,
					gTagManagerId: company.cmpGoogleTagManagerId,
					isChatBot: company.cmpIsChatBot,
					chatBotCode: company.cmpCprCode,
					chatBotProviderId: company.cmpCprId,
					isPixel: company.cmpIsPixel,
					pixelCode: company.cmpPixelCode,
					isSmsEnabled: company.cmpIsSmsEnabled,
					isWhatsappEnabled: company.cmpIsWhatsappEnabled,
					whatsappProviderId: company.cmpWprId,
					whatsappSecretKey: company.cmpWhatsappSecretKey,
					whatsappNamespace: company.cmpWhatsappNamespace,
					whatsappClientId: company.cmpWhatsappClientId,
					whatsAppNotificationTypes: company.whatsAppNotificationTypes.map((x) => ({
						name: x.cwnName,
						type: x.cwnNotificationType,
					})),
				};
			},
			deep: true,
		},
	},
};
</script>

<style></style>
